@import url('https://fonts.googleapis.com/css?family=Noto+Sans+TC:400,500,700&display=swap&subset=chinese-traditional');

:root {
  --doc-height: 100%;
}

input,
textarea,
select,
button {
  -webkit-tap-highlight-color: transparent;
  font-family: Gilroy, Noto Sans CJK TC, Noto Sans TC, Helvetica, Arial,
    sans-serif;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
}

a {
  color: #125769;
}
