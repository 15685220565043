@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local('Gilroy Regular '), local('Gilroy-Regular'),
    url('./files/gilroy-regular.woff2') format('woff2'),
    url('./files/gilroy-regular.woff') format('woff');
}

@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: local('Gilroy Medium '), local('Gilroy-Medium'),
    url('./files/gilroy-medium.woff2') format('woff2'),
    url('./files/gilroy-medium.woff') format('woff');
}

@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: local('Gilroy Bold '), local('Gilroy-Bold'),
    url('./files/gilroy-bold.woff2') format('woff2'),
    url('./files/gilroy-bold.woff') format('woff');
}
